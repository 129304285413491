.home {

  &.index {

    .main-content {
      background-color: transparent;
    }

    .content-wrapper {
      height: 100%;
    }

    main {
      background-image: url('/dist/images/home/design-01-bg-cenario.webp');
      background-position: left 5% bottom 50%, right bottom;
      background-repeat: no-repeat, no-repeat,;
      background-size: auto, cover;
      display: table;
      width: 100%;

      @media (max-width: 600px) {
        background: url('/dist/images/home/design-01-bg-cenario.webp');
        background-position: right bottom;
        background-repeat: no-repeat,;
        background-size: cover;
      }

      .main-content {
        @include media-breakpoint-down(xs) {
          margin-bottom: 0;
        }

        @include media-breakpoint-up(sm) {
          max-height: 60%;
        }

        display: table-cell;
        margin-bottom: 20rem;
        vertical-align: middle;
        width: 100%;

        .search-wrapper {
          display: flex;
          justify-content: center;
        }

        form {
          @include media-breakpoint-down(md) {
            .margin-sm {
              margin-bottom: 1rem;
            }
          }
        }
      }

      h2 {
        @include media-breakpoint-down(sm) {
          font-size: 8.8rem;
        }

        @include media-breakpoint-down(xs) {
          font-size: 4rem;
          line-height: 5rem;
        }

        color: $concrete;
        font-size: 10rem;
        font-weight: bold;
        line-height: 10rem;
        text-shadow: .1rem .1rem .6rem $black;
      }

      $alpha-black: rgba($black, .25);

      .corretores {
        color: $concrete;
        text-align: center;
        text-transform: uppercase;

        &.disponiveis {
          display: block;
          font-weight: bold;
        }

        p {
          @include media-breakpoint-down(sm) {
            font-size: 2.8rem;
            line-height: 2.7rem;
          }

          @include media-breakpoint-down(xs) {
            font-size: 1.5rem;
            line-height: 1.7rem;
          }

          font-size: 3.5rem;
          line-height: 4.7rem;
          text-shadow: 0 .4rem .4rem $alpha-black;

          > span {
            font-weight: bold;
          }
        }
      }

      .search {
        @include media-breakpoint-down(sm) {
          margin-top: 2.6rem;
          text-align: center;
          width: 90%;
        }

        @include media-breakpoint-down(xs) {
          margin-top: .8rem;
        }

        color: $white;
        margin-top: 5rem;
        width: 80%;

        p {
          @include media-breakpoint-down(sm) {
            font-size: 2rem;
            padding-bottom: 1.5rem;
          }

          @include media-breakpoint-down(xs) {
            font-size: 1.6rem;
            padding-bottom: .3rem;
          }

          color: $concrete;
          font-size: 2.6rem;
          padding-bottom: 2rem;
          text-shadow: 0 .4rem .4rem $alpha-black;
          text-transform: uppercase;
        }

        .btn-busca {
          //background-color: $pcb;
          //color: lighten($pcb, 20%);
          height: 4rem;
        }
      }
    }

    &.auto-contrast {
      &.index {
        .corretores {
          h2 {
            background-color: $black;
          }

          p {
            background-color: $black;
            display: inline;
          }
        }
      }
    }

    &.layout-01 {
      header {
        .top-bar {
          .top-menu-buttons {
            a {
              background: $yellow-supernova;
              color: $black;

              &:hover {
                background: darken($yellow-supernova, 5%);
              }
            }
          }
        }
      }

      main {
        background-image: url('/dist/images/home/design-01-bg-personagem.png'), url('/dist/images/home/design-01-bg-cenario.webp');

        @media (max-width: 600px) {
          background: url('/dist/images/home/design-01-bg-cenario.webp');
        }
      }

      footer {
        .social-media {
          background: $yellow-supernova;

          &:hover {
            background: darken($yellow-supernova, 5%);
          }
        }
      }
    }

    &.layout-02 {
      header {
        .top-bar {
          background: $yellow-supernova;

          .top-menu-buttons {
            a {
              background: $blue-ribbon;

              &:hover {
                background: darken($blue-ribbon, 10%);
              }
            }
          }

          .acessibility {
            a {
              color: $black;
            }
          }
        }
      }

      main {
        background-image: url('/dist/images/home/design-02-bg-personagem.png'), url('/dist/images/home/design-02-bg-cenario.webp');

        @media (max-width: 600px) {
          background: url('/dist/images/home/design-02-bg-cenario.webp');
        }
      }

      footer {
        background: $yellow-supernova;
        color: $black;

        a {
          color: $black;
        }

        .social-media {
          background: $blue-ribbon;
          color: $white;

          &:hover {
            background: darken($blue-ribbon, 10%);
          }
        }
      }
    }

    &.layout-03 {
      header {
        .top-bar {
          background: $yellow-supernova;

          .acessibility {
            a {
              color: $black;
            }
          }
        }
      }

      main {
        background-image: url('/dist/images/home/design-03-bg-personagem.png'), url('/dist/images/home/design-03-bg-cenario.webp');

        @media (max-width: 600px) {
          background: url('/dist/images/home/design-03-bg-cenario.webp');
        }
      }

      footer {
        background: $yellow-supernova;
        color: $black;

        a {
          color: $black;
        }

        .social-media {
          color: $white;
        }
      }
    }

    &.layout-04,
    &.layout-05,
    &.layout-06 {
      header {
        .top-bar {
          .top-menu-buttons {
            a {
              background: $yellow-supernova;
              color: $black;

              &:hover {
                background: darken($yellow-supernova, 5%);
              }
            }
          }
        }
      }

      footer {
        background: $yellow-supernova;
        color: $black;

        a {
          color: $black;
        }

        .social-media {
          color: $white;
        }
      }
    }

    &.layout-04 {

      main {
        background-image: url('/dist/images/home/design-04-bg-personagem.png'), url('/dist/images/home/design-04-bg-cenario.webp');
        background-position: left 5% bottom 0, left bottom;

        @media (max-width: 600px) {
          background: url('/dist/images/home/design-04-bg-cenario.webp');
        }
      }
    }

    &.layout-05 {

      main {
        background-image: url('/dist/images/home/design-05-bg-personagem.png'), url('/dist/images/home/design-05-bg-cenario.webp');
        background-position: left 0 bottom 0, left bottom;

        @media (max-width: 600px) {
          background: url('/dist/images/home/design-05-bg-cenario.webp');
        }
      }
    }

    &.layout-06 {

      main {
        background-image: url('/dist/images/home/design-06-bg-personagem.png'), url('/dist/images/home/design-06-bg-cenario.webp');
        background-position: left 0 bottom 0, left bottom;

        @media (max-width: 600px) {
          background: url('/dist/images/home/design-06-bg-cenario.webp');
        }
      }
    }
  }
  //fim .index
}//fim .home
