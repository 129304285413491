/** when changing this file, do not change this code snippet */
$icomoon-font-family: "creci_imob" !default;
$icomoon-font-path: "/dist/fonts" !default;
$icomoon-font-build: "fddaje" !default;

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?#{$icomoon-font-build}') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?#{$icomoon-font-build}') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?#{$icomoon-font-build}##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}
/** when changing this file, do not change this code snippet */

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: $icomoon-font-family !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-group:before {
  content: "\f0c0";
}

.icon-users:before {
  content: "\f0c0";
}

.icon-share-alt:before {
  content: "\f1e0";
}

.icon-chevron-up:before {
  content: "\f077";
}

.icon-angle-double-up:before {
  content: "\f102";
}

.icon-angle-double-down:before {
  content: "\f103";
}

.icon-heart-o:before {
  content: "\e914";
}

.icon-heart:before {
  content: "\e915";
}

.icon-user:before {
  content: "\f008";
}

.icon-edit:before {
  content: "\f044";
}

.icon-pencil-square-o:before {
  content: "\f044";
}

.icon-eye:before {
  content: "\f06e";
}

.icon-credit-card:before {
  content: "\f09d";
}

.icon-exchange:before {
  content: "\f0ec";
}

.icon-coffee:before {
  content: "\f0f4";
}

.icon-paper-plane:before {
  content: "\f1d8";
}

.icon-send:before {
  content: "\f1d8";
}

.icon-map-o:before {
  content: "\f278";
}

.icon-info:before {
  content: "\e916";
}

.icon-file-doc:before {
  content: "\e910";
}

.icon-file-xls:before {
  content: "\e911";
}

.icon-file-ppt:before {
  content: "\e912";
}

.icon-file-adobe:before {
  content: "\e913";
}

.icon-chevron-down:before {
  content: "\e902";
}

.icon-futbol-o:before {
  content: "\e903";
}

.icon-location:before {
  content: "\e947";
}

.icon-enlarge:before {
  content: "\e98b";
}

.icon-search:before {
  content: "\f002";
}

.icon-star:before {
  content: "\f005";
}

.icon-star-o:before {
  content: "\f007";
}

.icon-check:before {
  content: "\f00c";
}

.icon-close:before {
  content: "\f00e";
}

.icon-home:before {
  content: "\f015";
}

.icon-play-circle-o:before {
  content: "\f01d";
}

.icon-lock:before {
  content: "\f023";
}

.icon-tag:before {
  content: "\f02b";
}

.icon-tags:before {
  content: "\f02c";
}

.icon-camera1:before {
  content: "\f030";
}

.icon-image:before {
  content: "\f03e";
}

.icon-plus:before {
  content: "\f067";
}

.icon-asterisk:before {
  content: "\f069";
}

.icon-calendar:before {
  content: "\f073";
}

.icon-comment:before {
  content: "\f075";
}

.icon-arrows-v:before {
  content: "\f07d";
}

.icon-bar-chart:before {
  content: "\f080";
}

.icon-sign-out:before {
  content: "\f08b";
}

.icon-copy:before {
  content: "\f0c5";
}

.icon-bars:before {
  content: "\f0c9";
}

.icon-money:before {
  content: "\f0d6";
}

.icon-money2:before {
  content: "\f0d7";
}

.icon-comment-o:before {
  content: "\f0e5";
}

.icon-bell:before {
  content: "\f0f3";
}

.icon-file-text-o:before {
  content: "\f0f6";
}

.icon-angle-left:before {
  content: "\f104";
}

.icon-angle-right:before {
  content: "\f105";
}

.icon-quote-left:before {
  content: "\f10d";
}

.icon-play-circle:before {
  content: "\f144";
}

.icon-file-text1:before {
  content: "\f15c";
}

.icon-car:before {
  content: "\f1b9";
}

.icon-tty:before {
  content: "\f1e4";
}

.icon-trash1:before {
  content: "\f1f8";
}

.icon-street-view:before {
  content: "\f21d";
}

.icon-bed:before {
  content: "\f236";
}

.icon-calendar-check-o:before {
  content: "\f274";
}

.icon-black-tie:before {
  content: "\f27e";
}

.icon-handshake-o:before {
  content: "\f2b5";
}

.icon-address-card:before {
  content: "\f2bb";
}

.icon-user-circle-o:before {
  content: "\f2be";
}

.icon-bath:before {
  content: "\f2cd";
}

.icon-coins:before {
  content: "\e904";
}

.icon-camera:before {
  content: "\e905";
}

.icon-corner-down-left:before {
  content: "\e906";
}

.icon-corner-down-right:before {
  content: "\e907";
}

.icon-download-cloud:before {
  content: "\e908";
}

.icon-file-text:before {
  content: "\e909";
}

.icon-inbox:before {
  content: "\e90a";
}

.icon-mail:before {
  content: "\e90b";
}

.icon-paperclip:before {
  content: "\e90c";
}

.icon-refresh-cw:before {
  content: "\e90d";
}

.icon-trash:before {
  content: "\e90e";
}

.icon-trash-2:before {
  content: "\e90f";
}

.icon-tv:before {
  content: "\e901";
}

.icon-location-food:before {
  content: "\e900";
}

.icon-spinner8:before {
  content: "\e981";
}

.icon-bookmark:before {
  content: "\e9d2";
}
