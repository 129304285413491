.btn {
  background-color: $concrete;
  border: 0;
  cursor: pointer;
  padding: 1rem 2rem;
  text-decoration: none;
  text-transform: uppercase;

  &.btn-primary {
    background-color: $pcb-green; //$monza;
    color: $white;

    &[disabled] {
      background-color: $dove-gray;
    }
  }

  &.btn-secondary {
    background-color: $deep-cerulean;
    color: $white;

    &[disabled] {
      background-color: $dove-gray;
    }
  }

  &.btn-primary-green {
    background-color: $pcb-green; //$fern;
    color: $white;

    &[disabled] {
      background-color: $dove-gray;
    }
  }

  &.btn-details {
    background-color: $pcb-green; //$fern;
    color: $white; //$white;

    &[disabled] {
      background-color: $dove-gray;
    }
  }

  &.btn-edit {
    background-color: $deep-cerulean;
    color: $white;

    &[disabled] {
      background-color: $dove-gray;
    }
  }

  &.btn-delete {
    background-color: $monza;
    color: $white;

    &[disabled] {
      background-color: $dove-gray;
    }
  }

  &.btn-restore {
    background-color: $monza;
    color: $white;

    &[disabled] {
      background-color: $dove-gray;
    }
  }

  &.btn-contact {
    background-color: $monza;
    color: $white;

    &[disabled] {
      background-color: $dove-gray;
    }
  }

  &.btn-green {
    background-color: $pcb-dark-green; //$fern;
    color: $white; //$white;

    &[disabled] {
      background-color: $dove-gray;
    }
  }

  &.btn-yellow {
    background-color: $pcb-dark-yellow; //$fern;
    color: $pcb-dark-green; //$white;

    &[disabled] {
      background-color: $dove-gray;
    }
  }

  &.btn-blue {
    background-color: $pcb-dark-blue; //$fern;
    color: $white; //$white;

    &[disabled] {
      background-color: $dove-gray;
    }
  }

  &.btn-generic-green {
    background-color: $pcb-darker-green; //$fern;
    color: $white; //$white;

    &[disabled] {
      background-color: $dove-gray;
    }
  }

  &.modal-delete-definitivo {
    font-size: 85%;
  }

  &.btn-lg {
    line-height: 1.5;
    padding: 1.5rem 1.2rem;
  }

  &.btn-sm {
    padding: .5rem 1rem;

    .modal-delete-definitivo {
      font-size: 85%;
    }
  }

  @mixin btn-full {
    height: 100%;
    width: 100%;
  }

  &.btn-full {
    @include btn-full;
  }

  &.btn-form-inline {
    height: 3.8rem;
  }

  @include media-breakpoint-down(md) {
    &.btn-full-sm {
      @include btn-full;
    }
  }

  &.inline-block {
    display: inline-block;
    padding: 1.3rem 2rem;
  }
}
