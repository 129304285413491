.accordion {

  margin: 1rem 0;

  .title,
  .content {
    border: 1px solid $alto;
    padding: 2.5rem 1.5rem;
  }

  .content {
    border-top: 0;
    display: none;

    &.show {
      display: block;
    }
  }

  .title {
    border-top: 3px solid $monza;
    cursor: pointer;
    text-transform: uppercase;
  }

}
