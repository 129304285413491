.busca {

  .total-realstate,
  .total-realstateusers {
    display: block;
    font-size: 1.6rem;
    font-weight: 700;
    text-transform: uppercase;

    span {
      display: inline-block;
      text-align: right;
      width: 8rem;
    }
  }

  .total-realstate {
    color: $chicago;
    margin-bottom: 1rem;

    span {
      font-size: 2.6rem;
    }
  }

  .total-realstateusers {
    color: $fern;
    margin-bottom: 2rem;

    span {
      font-size: 2rem;
    }
  }

  .filter-tags {
    .tag {
      background-color: $deep-cerulean;
      color: $white;
      cursor: pointer;
      display: inline-block;
      margin-bottom: .5rem;
      padding: .5rem 2rem .5rem 1rem;
      position: relative;
      text-align: left;


      i {
        position: absolute;
        right: .5rem;
        top: .7rem;
      }
    }
  }

  .mobile-only {
    display: none;
    text-align: right;
  }

  .filter-header {
    display: none;
  }

  @include media-breakpoint-down(sm) {
    .mobile-only {
      display: block;
    }

    .filter-header {
      display: block;
    }

    .filter-wrapper {
      display: none;

      &.active {
        background: $white;
        bottom: 0;
        display: block;
        height: 100%;
        left: 0;
        overflow: auto;
        padding: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 9;

        .filters-general {
          width: 100%;
        }
      }
    }
  }

  .order-wrapper {
    justify-content: space-between;
  }

  .filter-wrapper {
    @include ad-filter;
  }

  label {
    &.slider-label {
      display: block;
      padding-bottom: 45px;
      width: 100%;
    }
  }
}
