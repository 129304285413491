// sass-lint:disable no-important
.anuncio {
  &.index {
    &.auto-contrast {
      .real-state-resume-holder,
      .basic-advertisement {
        background: $white;

        h2,
        h3,
        h4,
        label,
        i,
        p,
        strong,
        em {
          color: $black !important;
        }

        .btn-favoritar-call {
          i {
            color: $white !important;
          }
        }
      }

      .head-tabs {
        border-bottom-color: $black !important;

        i {
          color: $white !important;
        }

        .active {
          a,
          span,
          i {
            background: $white !important;
            color: $black !important;
          }
        }
      }

      .messagecontainer {
        background-color: $white;

        label {
          color: $black !important;
        }
      }

      .return-page-result-search {
        background-color: $white !important;
        color: $black !important;
      }
    }
  }
}
