
.tabs-creci {
  &.primary {
    .head-tabs {
      border-bottom-color: $pcb-darker-green; //$monza;

      .nav-item {
        &.active {
          background: $pcb-darker-green; //$monza;
          border-bottom-color: $pcb-darker-green; //$monza;
        }
      }
    }
  }

  &.secondary {
    .head-tabs {
      border-bottom-color: $pcb-dark-green; //$deep-cerulean;

      .nav-item {
        &.active {
          background: $pcb-dark-green; //$deep-cerulean;
          border-bottom-color: $pcb-dark-green; //$deep-cerulean;
        }
      }
    }
  }

  &.tab-footer {
    display: flex;
    flex-direction: column-reverse;

    .head-tabs {
      border-bottom: 0;
      border-top: .3rem solid $chicago;
    }

    &.primary {
      .head-tabs {
        border-top: .3rem solid $pcb-darker-green; //$monza;
      }
    }

    &.secondary {
      .head-tabs {
        border-top: .3rem solid $pcb-dark-green; //$deep-cerulean;
      }
    }
  }

  &.tab-head-full {
    .head-tabs {
      .nav-item {
        margin-right: 0;
      }
    }
  }

  .head-tabs {
    border-bottom: .3rem solid $chicago;
    display: flex;
    justify-content: flex-start;
    z-index: 1;

    .nav-item {
      align-items: center;
      background: $white;
      border: 0;
      cursor: pointer;
      display: flex;
      flex-grow: 1;
      height: 4rem;
      justify-content: center;
      margin-right: 2rem;

      a {
        align-items: center;
        flex-grow: 1;
        height: 4rem;
        padding: 14px;
        text-align: center;
      }

      span {
        cursor: pointer;
        margin-right: .3rem;
      }

      &.active {
        background: $pcb-darker-green; //$chicago;

        .nav-link {
          color: $white;
          cursor: pointer;
          display: block;
        }
      }

      .nav-link {
        color: $black;
        cursor: pointer;
        display: block;
        text-decoration: none;

        * {
          cursor: pointer;
        }

        label {
          display: block;
          font-size: 1.2rem;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
    }
  }

  &.xs-horizontal {
    @include media-breakpoint-down(xs) {
      .head-tabs {
        flex-direction: column;

        .nav-item {
          margin-right: 0;
        }
      }
    }
  }

  .body-tab-content {
    background: $white;
    overflow: auto; // Fixes strange video height in IE
    &.midia-wrapper {

      .images-grid {
        display: none;

        &:first-child {
          display: block;
        }
      }
    }

    &.full-element-padding {
      .body-tab-pane {
        padding: 0;
        height: 35vmax;
      }
    }

    .body-tab-pane {
      display: none;
      padding: 2.2rem 2.6rem;
      height: 100%;

      &.show {
        display: block;
      }
    }
  }
}
