.modal-detail-mail {
  display: none;
  position: absolute;

  &.show {
    background: $black-modal-img;
    display: block;
    height: 100%;
    left: 0;
    overflow: auto;
    padding-top: 100px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;

    > span {
      color: $white;
      cursor: pointer;
      font-size: 45px;
      position: absolute;
      right: 2rem;
      top: 2rem;
    }
  }

  .modal-body {
    @include media-breakpoint-down(xs) {
      height: 100%;
      width: 100%;
      margin-left: 0;
    }

    background: $white;
    display: flex;
    justify-content: center;
    width: 50%;
    margin-left: 23%;
    padding: 54px;

    form {
      width: 100%;
    }
  }
}
