.feedback {
  &.info {
    $deep-cerulean-shade: lighten($deep-cerulean, 50%);
    background-color: $deep-cerulean-shade;
    border: 1px solid $deep-cerulean;
    font-size: 1.5rem;
    padding: 2rem;
    text-align: center;
  }
}


// sass-lint:disable no-important
.auto-contrast {
  .feedback {
    background-color: $white;
    border: 1px solid $yellow;
    color: $black;
  }
}
