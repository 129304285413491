.real-estate-details {
  .advertisement {

    &.basic-advertisement {
      background-color: $white;
    }

    .tabs-creci {
      .head-tabs {
        .nav-item {
          margin-right: 0;
        }
      }
    }

    .real-state-resume-holder {
      background-color: $white;
    }

    .real-estate-resume {
      margin-bottom: 1rem;
      padding: 2rem;

      h2 {
        color: $pcb-green; //$deep-cerulean;
        font-size: 1.8rem;
        font-weight: 700;
        margin-bottom: 1rem;
      }

      .btn-favoritar-call {
        @include media-breakpoint-down(sm) {
          float: right;
          border-radius: 1rem;
        }

        background-color: $pcb-green; //$deep-cerulean;
        color: $white;

        .icon-heart {
          display: none;
          color: $white;
        }

        .add {
          display: inline-block;
        }

        .remove {
          display: none;
        }

        &.active {
          background-color: $pcb-green; //$monza;

          .add {
            display: none;
          }

          .remove {
            display: inline-block;
          }

          .icon-heart {
            display: inline-block;
          }

          .icon-heart-o {
            display: none;
          }
        }
      }

      .icons {
        color: $pcb-green; //$deep-cerulean;
        font-size: 1.5rem;
        font-weight: normal;
        margin: 1rem .1rem;

        i {
          margin-right: .75rem;
        }
      }

      .label-value {
        font-size: 1.4rem;
        font-weight: 400;

        &.label-cod {
          font-weight: 700;
        }
      }

      .sale-value {
        color: $pcb-green; //$monza;
        font-size: 2.3rem;
        font-weight: 700;
      }

      .square-meter-value {
        color: $pcb-green; //$monza;
        font-size: 1.8rem;
        font-weight: 700;
      }
    }

    .grid-icons {
      background-color: $white;
      flex-direction: row;
      margin: 0;
      padding: 2rem 0;
      text-align: center;

      .item-list-icons {
        float: left;
        margin: 0 auto .5rem;
        text-align: center;
        text-transform: uppercase;

        .form-group {
          .num-itens {
            font-size: 1.5rem;
            font-weight: bold;
          }

          .desc-itens {
            font-size: 1.2rem;
            font-weight: bold;
          }

          .num-more {
            font-size: 1.4rem;
          }

          .txt-more {
            font-size: 1rem;
            font-weight: 400;
          }
        }

        i {
          color: $pcb-green; //$deep-cerulean;
          margin-right: .5rem;
        }

        @include media-breakpoint-up(xs) {
          width: calc(100% / 3);
        }

        @include media-breakpoint-up(md) {
          width: calc(100% / 4);
        }

        @include media-breakpoint-up(lg) {
          width: 12%;
        }
      }

      .item-list-bar {
        background-color: $gray;
        width: 1px;
      }
    }
  }

  .title-description {
    background-color: $white;
    margin: 2rem 0 0;
  }

  .grid-money {
    display: flex;
    justify-content: space-around;
    padding: 2rem;
    text-align: center;
    text-transform: uppercase;
  }

  .mt-15 {
    margin-top: 1.5rem;
    padding: 2rem;
  }

  .title {
    color: $pcb-green; //$deep-cerulean;
    margin: 1rem .1rem;

    &.main-title {
      font-size: 1.8rem;
    }

    &.sub-title {
      font-size: 1.4rem;
    }
  }

  .row-description {
    padding: 2rem;
  }

  .content-text {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 2rem;
    word-wrap: break-word;
  }

  .list-icones {
    dd {
      font-weight: 400;
      margin: 1rem 0;

      i {
        color: $pcb-green; //$deep-cerulean;
        margin-right: .4rem;
      }
    }
  }

  dd {
    label {
      font-size: 1.5rem;
    }
  }

  .characteristcs {
    dd {
      @include media-breakpoint-down(sm) {
        width: calc(100% / 3);
      }

      float: left;
      width: calc(100% / 4);
    }
  }

  .map-holder {
    background-color: $white;
    box-shadow: 0 .5rem .5rem $black-shadow-broker;
    margin-top: 1rem;
    padding: .5rem;
  }

  .map-size {
    height: 330px;
  }

  .season-title {
    margin-top: 1.5rem;
    padding: 2rem 2rem 0;
  }

  .season-details {
    padding: 0 3rem;
  }

  .period-table {
    margin-top: 2rem;

    .align-center {
      text-align: center;
      vertical-align: middle;
    }

    tr {
      th {
        background-color: $deep-cerulean;
        border: 2px solid $white;
        color: $white;
        padding: 1rem;
      }

      .th-first {
        width: 25%;
      }

      td {
        background-color: $white;

        .icon {
          cursor: pointer;
          font-size: 1.5rem;

          :hover {
            color: $deep-cerulean;
          }
        }
      }

      .comments {
        background-color: $jagged-ice;
        border: 2px solid $white;
      }
    }
  }

  .margin-entre-hr {
    border: .1rem solid $chicago;
    margin-bottom: 2.6rem;
    margin-top: 2.8rem;
  }

  .images-grid {
    cursor: pointer;

    .broker {
      .broker-name {
        @include media-breakpoint-down(md) {
          font-size: 1rem;
        }

        @include media-breakpoint-down(sm) {
          font-size: 1.7rem;
        }

        font-size: 1.7rem;
      }

      .broker-document {
        @include media-breakpoint-down(md) {
          font-size: .8rem;
        }

        @include media-breakpoint-down(sm) {
          font-size: 1.2rem;
        }

        font-size: 1.2rem;
      }
    }
  }

  .content {
    background: $fern-content-preview;
    color: $grey;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 20px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 999;

    h1 {
      @include media-breakpoint-down(md) {
        font-size: 3.5rem;
        line-height: 4rem;
      }

      @include media-breakpoint-down(sm) {
        font-size: 2rem;
        line-height: 2.5rem;
      }

      font-size: 5rem;
      font-style: italic;
      line-height: 5.5rem;
      text-transform: uppercase;
      word-wrap: break-word;

      &::before {
        content: open-quote;
      }

      &::after {
        content: close-quote;
      }
    }
  }

  .panel-video,
  #video360 {
    video {
      width: 100%;
    }

    .tns-controls {
      top: 60%;
    }
  }

  #video360 {
    .message-ie {
      display: none;

      &.show {
        display: block;
      }
    }
  }

  .advertisement-no-back {
    background-color: transparent;

    &.basic-advertisement {
      background-color: $white;
    }

    .sendmessage {
      margin: 0 0 2rem;

      .title {
        color: $pcb-green; //$deep-cerulean;
        margin: 1rem .1rem;
        padding-left: 1.8rem;
      }

      .messagecontainer {
        background-color: $pcb-lighter-green; //$deep-cerulean;
        color: $white;
        font-size: 1.6rem;
        padding: 2rem;

        input,
        textarea {
          border: 0;
          margin: .6rem 0 1rem;
          padding: .5rem;
          width: 100%;
          border-radius: 0.3rem;
        }

        label {
          > input {
            margin-right: .5rem;
            width: auto;
          }
        }

        .label-check {
          font-size: 1.2rem;
          font-weight: 400;

          input {
            vertical-align: sub;
          }
        }

        .btn {
          font-size: 1.2rem;
          margin: 0;
          margin-top: 1rem;
          float: right;
        }
      }
    }
  }

  .grid-calendar {
    background-color: $white;
    padding: 0 0 1.5rem 1.5rem;
  }
}
