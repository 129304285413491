@mixin ad-filter {
  .filter-header {
    background-color: $deep-cerulean;
    color: $white;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.6rem;
    padding: 1rem 1.5rem;

    .icon-search {
      padding-right: 1rem;
    }

    .icon-close {
      cursor: pointer;
      float: right;
    }
  }

  .filters-general {
    background: $white;
    padding: 1.7rem;

    h4 {
      color: $black;
      font-weight: 900;
      padding-bottom: 1rem;
      text-transform: uppercase;
    }

    label {
      cursor: pointer;
    }

    span {
      margin-right: .8rem;
    }

    hr {
      background: $text-shadow;
      border: 0;
      height: .2rem;
      margin: 1.3rem 0;
    }

    .checkbox-filters-general {
      align-items: center;
      display: flex;
      line-height: 2.1rem;

      input {
        margin-right: .5rem;
      }
    }

    .model-interval-filters {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 1rem;
    }

    .renger-group-filters {
      margin-bottom: 3.6rem;

      .model-interval-smaller-filters {
        margin-bottom: 15px;
      }
    }

    .button-filters {
      margin: 2rem 0;

      > div {
        padding-bottom: 1rem;
      }

      label {
        margin-bottom: 1rem;
      }

      a {
        border-radius: 6px;
        display: inline-block;
        margin-bottom: .4rem;
        margin-right: .3rem;
      }
    }

    #filter-ajax-subset {
      .spinner {
        height: 30px;
        margin: 20px auto;
        width: 30px;
      }
    }
  }
}
