.loading {
  position: relative;

  &.border-box {
    &::after {
      background-clip: border-box;
    }
  }

  &::after {
    @include media-breakpoint-down(sm) {
      padding: 0;
      position: fixed;
    }

    $gray-gallery-rgb: rgba($gallery, .3);
    animation: fadeIn 1s;
    animation-fill-mode: both;
    background-clip: content-box;
    background-color: $gray-gallery-rgb;
    bottom: 0;
    content: '';
    left: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
  }
}
