@import '../../vendor/awesomplete/awesomplete';

.awesomplete {
  position: relative;
  z-index: 5;

  ul {
    border: 1px solid $alto;
    position: relative;
    z-index: 3;

    li {
      color: $chicago;
      padding: .7rem;

      &:hover {
        background: $alto;
        z-index: 1;
      }

      mark {
        background-color: $deep-cerulean;
        color: $white;
      }
    }
  }
}

.autocomplete {
  li {
    color: $chicago;
  }
}
