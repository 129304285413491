// colors to be used in the next and previous buttons
$next-previous: rgba(51, 51, 51, .4);
$tiny-write: #f2f2f2;

.tns-outer {
  height: 100%;
  padding: 0;
  position: relative;

  .tns-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    outline: none;
    pointer-events: none;
    position: absolute;
    top: 42.7%;
    width: 100%;
    z-index: 1;

    button {
      background-color: $next-previous;
      border: 0;
      color: $tiny-write;
      font-size: 42px;
      outline: none;
      padding: 0 1rem;
      pointer-events: all;

      &[disabled] {
        visibility: hidden;
      }
    }

    [hidden] {
      display: none;
    }

    [aria-controls],
    [data-action] {
      cursor: pointer;
    }

    .ms-touch {
      -ms-overflow-style: none;
      -ms-scroll-chaining: none;
      -ms-scroll-snap-type: mandatory;
      -ms-scroll-snap-points-x: snapInterval(0%, 100%);
      overflow-x: scroll;
      overflow-y: hidden;
    }

    .tns-inner {
      position: relative;
      height: 100%;

      .tns-slider {
        transition: all 0s;
        height: 100%;

        > .tns-item {
          box-sizing: border-box;
          height: 100%;
        }
      }
    }
  }
}

.tns-horizontal {
  &.tns-subpixel {
    white-space: nowrap;
    height: 100%;

    > .tns-item {
      display: inline-block;
      position: relative;
      vertical-align: top;
      white-space: normal;
      height: 100%;
    }
  }

  .tns-no-subpixel {
    >.tns-item {
      float: left;
      margin-right: -100%;
    }

    &::after {
      clear: both;
      content: '';
      display: table;
    }
  }
}

.tns-no-calc {
  left: 0;
  position: relative;
}

.tns-gallery {
  left: 0;
  min-height: 1px;
  position: relative;
  height: 100%;

  >.tns-item {
    -webkit-transition: transform 0s, opacity 0s;
    -moz-transition: transform 0s, opacity 0s;
    left: -100%;
    position: absolute;
    transition: transform 0s, opacity 0s;
    height: 100%;
  }

  >.tns-moving {
    -webkit-transition: all .25s;
    -moz-transition: all .25s;
    transition: all .25s;
  }
}


.tns-lazy-img {
  -webkit-transition: opacity .6s;
  -moz-transition: opacity .6s;
  opacity: .6;
  transition: opacity .6s;
  height: 100%;

  &.loaded {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    opacity: 1;
    width: 100%;
  }
}

.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s;
}

.tns-ovh {
  height: 100%;
  overflow: hidden;
}

.tns-hdx {
  overflow-x: hidden;
}

.tns-hdy {
  overflow-y: hidden;
}

.tns-visually-hidden {
  left: -10000em;
  position: absolute;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  filter: alpha(opacity=100);
  opacity: 1;
  z-index: 0;
}

.tns-normal,
.tns-fadeOut {
  filter: alpha(opacity=0);
  opacity: 0;
  z-index: -1;
}

