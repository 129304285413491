$black-favorite-shadow: rgba(50, 50, 50, .47);

$top-favorites: 134px;
$top-favorites-right: 20px;
$favorites-width: 300px ;
$top-favorites-first-height: 50px;

$favorites-large-screen-show: 1680px;

@mixin show-panel {
  & {
    right: 0;
  }
}

.favorites {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  position: fixed;
  right: -$favorites-width;
  top: $top-favorites;
  transition: right .4s ease-in-out;
  will-change: right;
  z-index: 9999;

  * {
    pointer-events: auto;
  }

  .favorites-panel {
    border-radius: 1px;
    width: 300px;
  }

  &.favorites-mobile-hide {
    * {
      cursor: pointer;
    }
  }

  .favorites-icon-mobile {
    background: $pcb-blue; //$deep-cerulean;
    color: $white;
    cursor: pointer;
    display: inline-block;
    font-size: 1.4rem;
    left: -35px;
    margin-bottom: 0;
    margin-top: 0;
    padding: 1rem 2rem;
    position: relative;
    text-transform: uppercase;
    top: 5rem;
    transform: rotate(270deg);
    transform-origin: top right;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;

    label {
      cursor: pointer;
      user-select: none;

      .number {
        padding-left: .5rem;
      }
    }

    .icon-heart {
      margin-right: .5rem;
    }
  }

  .icon-close {
    cursor: pointer;
    display: none;
  }

  &.favorites-mobile-full {
    @include show-panel;

    @include media-breakpoint-down(xs) {
      &,
      .favorites-panel {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
      }

      .favorites-panel {
        width: 100%;

        .favorites-second {
          height: calc(100% - #{$top-favorites-first-height});
          max-height: calc(100% - #{$top-favorites-first-height});
        }
      }
    }

    z-index: 10;

    .favorites-panel {
      box-shadow: -5px 3px 11px $black-favorite-shadow;
    }
  }

  .favorites-first {
    background: $white;
    color: $pcb-blue; //$deep-cerulean;
    height: $top-favorites-first-height;
    margin-bottom: 0;
    margin-top: 0;
    padding: 15px;

    label {
      font-size: 2rem;
      margin-bottom: 0;
      position: relative;

      span {
        text-transform: none;
        margin-right: 12px;
      }
    }

    .icon-close {
      display: block;
      position: absolute;
      right: -10px;
      top: 2.8%;
    }
  }

  .favorites-second {
    background: $white;
    max-height: calc(100vh - #{$top-favorites + 75px});
    overflow: auto;
    padding: 2rem;

    .favorites-div-container {
      height: auto;
      margin: 0 0 2rem;
      position: relative;
      width: 100%;

      &:last-child {
        margin-bottom: 0;
      }

      .primary-link,
      .primary-link:hover {
        cursor: pointer;
        text-decoration: none;

        label {
          cursor: pointer;
        }
      }

      .remove-favorite {
        $alpha: rgba($monza, .8);

        background: $alpha;
        color: $white;
        cursor: pointer;
        font-weight: bold;
        padding: 5px 7px;
        position: absolute;
        right: .5rem;
        top: .5rem;
        transition: background .3s;

        &:hover {
          background: $monza;
        }
      }

      .favorites-div-img {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 104px;
        margin-bottom: 1rem;
      }

      .favorites-div-money,
      .favorites-div-text,
      .favorites-div-icon {
        margin-bottom: .5rem;
      }

      .favorites-div-money {
        label {
          color: $monza;
          font-size: 2.2rem;
          font-weight: bold;
        }
      }

      .favorites-div-text {
        color: $chicago;
        text-align: justify;

        label {
          font-size: 1.1rem;
          font-weight: normal;
          text-transform: none;
        }
      }

      .favorites-div-icon {
        color: $pcb-blue; //$deep-cerulean;
        display: flex;
        flex-direction: row;

        label {
          margin-right: 10px;
        }
      }
    }

    .empty-favorite {
      > div,
      > p {
        text-align: center;
      }

      > div {
        border: solid 1px $chicago;
        font-size: 1.4rem;
        margin-bottom: 2rem;
        padding-bottom: 1.8rem;
        padding-top: 1.8rem;
      }
    }
  }
}

// Only for search results, show favorites if the screen is large enough
body.busca.index {
  @media (min-width: $favorites-large-screen-show) {
    @include show-panel;
    // In this high resolution it cannot be closed
    .favorites-first span.icon-close {
      display: none;
    }
  }
}
