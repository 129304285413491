
.noUi-horizontal {
  height: 4px;

  .noUi-connect {
    background-color: $pcb-yellow; //$deep-cerulean;
    cursor: pointer;
  }
}

.noUi-target {
  background: $silver;
  border: 0;
  border-radius: 0;
  box-shadow: none;

  .noUi-handle {
    background: $pcb-yellow; //$deep-cerulean;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    height: 27px;
    left: 7px;
    top: -11px;
    width: 7px;

    &:before,
    &:after {
      content: '';
      display: none;
    }
  }
}

html:not([dir=rtl]) {
  .noUi-horizontal {
    .noUi-handle {
      right: -5px;
    }
  }
}

.min-range-input {
  width: 100%;
  color: $chicago;
  border: 0;
  font-size: 95%;
}

.max-range-input {
  @extend .min-range-input;
  text-align: right;
}
