.modal {
  align-items: center;
  background: $overlay-color;
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;

  .modal-dialog {
    @include media-breakpoint-down(xs) {
      height: 100%;
      width: 100%;
    }

    background-color: $concrete;
    border: 1px solid $text-shadow;
    //height: 280px;
    margin: 0 auto;
    //max-width: 500px;

    .modal-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;

      .modal-header {
        background-color: $pcb-green;
        color: $white;
        padding: 2rem;

        .close {
          color: $black;
          cursor: pointer;
          float: right;
          font-size: 3rem;
          font-weight: bold;
          text-align: center;
          width: 20px;
        }
      }

      .modal-body {
        padding: 2rem;
        width: 100%;
      }
    }
  }
}

.login-modal {
  z-index: 2;

  .btn-entrar,
  .btn-cadastrar {
    cursor: pointer;
    display: block;
    text-align: center;
    width: 100%;
  }

  .modal-body {
    span {
      display: block;
      font-weight: bold;
      margin: 20px;
      //text-align: center;
      text-transform: uppercase;
    }
  }
}
