
// sass-lint:disable no-important
body {
  &.auto-contrast {
    // Placeholders to make easear to mantain
    %bg-black-color-white {
      background-color: $black !important;
      color: $white !important;
    }

    %bg-white-color-black {
      background-color: $white !important;
      color: $black !important;
    }

    .head-tabs {
      border-bottom-color: $white !important;

      li {
        background: $black !important;

        &.active {
          background: $white !important;

          a {
            background: $white !important;

            label {
              color: $black !important;
            }
          }
        }
      }
    }

    .favorites {
      .favorites-first,
      .favorites-second,
      .favorites-icon-mobile {
        background-color: $black;
      }
    }

    .container {
      h1,
      h3,
      label,
      strong {
        color: $white;
      }

      // sass-lint:disable no-important
      button,
      input [type='button'],
      [type='reset'],
      [type='submit'],
      a {
        @extend %bg-black-color-white;

        border: 0;

        &:hover,
        &:focus {
          color: inherit;
        }
      }

      input,
      textarea,
      select {
        background: $black;
        border: 1px solid $white;
        color: $white;
      }

      hr {
        background: $white;
      }
    }

    // sass-lint:disable no-important
    > h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    label,
    strong,
    i,
    em,
    cite,
    q,
    b,
    u,
    dt,
    span,
    .checkbox-filters-general,
    .room-icons,
    .gallery-price,
    .show-favorites {
      color: $white !important; // No result reported
    }

    .room-icons,
    .gallery-broker-details {
      i {
        color: $white !important; // No result reported
      }
    }

    .gallery-buttons,
    .button-filters,
    .gallery-broker-details {
      button,
      a {
        @extend %bg-white-color-black;

        &:hover {
          @extend %bg-white-color-black;
        }
      }
    }

    .modal-body {
      label {
        color: $black !important;
      }
    }

    .autocomplete {
      &.open {
        ul {
          li {
            @extend %bg-black-color-white;
          }
        }
      }
    }

    .loading {
      &::after {
        $white-rgb: rgba($white, .5);
        background-color: $white-rgb;
      }
    }

  }
}

@import './auto-contrast/ac-real-estate-details';
