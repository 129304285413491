.grid-calendar-component {

  .calendar-component {
    .pika-single {
      box-shadow: 0 10px 9px -3px $overlay-color;
      height: 228px;
      margin-bottom: 2rem;
      z-index: 1;
    }

    .pika-select-year {
      pointer-events: none;
    }

    .pika-select-month {
      pointer-events: none;
    }

    .pika-single {
      &.is-bound,
      &.is-hidden {
        display: block;
      }

      .pika-lendar {
        float: none;
        width: auto;
      }
    }

    input,
    .pika-next,
    .pika-prev {
      display: none;
    }

    .calendar-item {
      float: left;
      margin: 0;
    }

    .is-disabled {
      background: $curious-blue;
      color: $blue-chill;
      cursor: pointer;
    }

    .is-invalid {
      cursor: not-allowed;
    }

    .pika-button {
      text-align: center;

      &:hover {
        background-color: $deep-cerulean;
        color: $black;
        font-weight: 700;
      }
    }

    .is-selected {
      &.pika-button,
      &.has-event,
      &.pika-button {
        background: $curious-blue;
        border-radius: 1px;
        box-shadow: none;
        color: $blue-chill;
      }

      .is-disabled {
        background: $curious-blue;
        color: $blue-chill;
      }
    }
  }

  .calendar-subtitle {
    margin-bottom: 1rem;
    text-align: center;
    vertical-align: middle;

    .align {
      margin: 0 auto;

      .item {
        float: left;
        margin-right: 1rem;
        padding-top: 1rem;

        .subtitle-info {
          border: 1px solid $silver;
          float: left;
          height: 1.5rem;
          margin-right: .5rem;
          margin-top: -.1rem;
          width: 1.5rem;
        }

        .available {
          background-color: $white;
        }

        .unavailable {
          background-color: $viking;
          border: 0;
        }
      }
    }
  }
}

.grid-calendar-component.is-readonly {
  pointer-events: none;
}
