$black-modal-advertisement: rgba(19, 19, 19, .95);

body {
  &.body-fixed {
    overflow: hidden;
  }
}


.real-estate {

  .modal-detail-img {

    &.show {
      background: $black-modal-advertisement;
      overflow: hidden;
      z-index: 1001;

      > span {
        font-size: 2.5rem;
        right: 5%;
        top: 3%;
      }
    }

    .tns-outer {
      margin: 0 auto;
      width: 85rem;
      margin-top: -4%;

      .tns-controls {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        outline: none;
        position: absolute;
        top: 23%;
        width: 100%;
        z-index: 1003;
      }

      .tns-nav {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        position: relative;
        top: 60%;
        z-index: 1004;
      }
    }

    .thumbnails {
      bottom: 0;
      padding-bottom: 2rem;
      position: absolute;
      width: 100%;

      > ul {
        margin: 2rem 0;
        text-align: center;

        > li {
          border: .5rem solid $black;
          cursor: pointer;
          display: inline-block;

          &.tns-nav-active {
            background: none;
            border-color: $gray;
          }

          > img {
            width: 7.3rem;
          }
        }
      }
    }

  }
}
