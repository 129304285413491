
.table-responsive {
  -ms-overflow-style: -ms-autohiding-scrollbar; // sass-lint:disable-line no-vendor-prefixes
  -webkit-overflow-scrolling: touch; // sass-lint:disable-line no-vendor-prefixes
  display: block;
  overflow-x: auto;
  width: 100%;
}

table {
  &.table {
    background-color: $white;
    border-collapse: collapse;
    margin-bottom: 1rem;
    max-width: 100%;
    width: 100%;

    tr {
      th,
      td {
        border-bottom: 1px solid $grey;
      }

      th {
        @include media-breakpoint-down(md) {
          padding: 2.1rem .2rem;
        }

        line-height: normal;
        padding: 2.1rem;

        &.text-left {
          padding-left: 1rem;
          text-align: left;
        }
      }

      td {
        padding: 1rem;

        &.center {
          text-align: center;
          vertical-align: middle;
        }

        &.actions {
          a {
            font-size: 1.5rem;
            padding: .4rem;
          }
        }
      }
    }

    &.table-bordered {
      th,
      td {
        border: 1px solid $grey;
      }
    }

    &.table-noborder {
      tbody,
      thead,
      tfooter,
      tr,
      th,
      td {
        border: 0;
      }
    }
  }
}
