body {
  background-color: $gallery;
  color: $chicago;
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem;
  line-height: 1.5rem;

  &.minus {
    font-size: .9rem;
  }

  &.default {
    font-size: 1.2rem;
  }

  &.plus {
    font-size: 1.4rem;
  }
}

main {
  .main-content {
    display: block;
  }

  .app-shell {
    &.spinner {
      display: none;
    }
  }
}

.main-title {
  color: $chicago;
  font-size: 1.6rem;
  font-weight: bold;
  margin: 3rem 0;
  text-transform: uppercase;
}

.img-fluid {
  max-height: 100%;
  max-width: 100%;
}
