@include media-breakpoint-down(lg) {
  .talk-to-broker-right {
    display: none;
  }
}

.talk-to-broker-right {
  box-shadow: 1.2rem 1.3rem 1.9rem -1.2rem $black-shadow-broker;
  padding: 0;
  position: absolute;
  right: 0;
  top: 300px;
  z-index: 1;

  .block {
    background-color: $gallery;
  }

  .resume {
    background-color: $white;
    padding-bottom: 1.5rem;
    width: 27rem;

    .talk-to-broker-title {
      color: $pcb-green;
      margin-bottom: 1rem;
      padding-left: 1.5rem;
    }

    label {
      text-transform: none;

      &.clean {
        display: inline;
        font-size: 1rem;
        font-weight: normal;
      }
    }

    input,
    textarea {
      border: 0;
    }

    input {
      &[type='text'] {
        height: 2rem;
        border-radius: 0.3rem;
      }
    }

    textarea {
      height: 5rem;
      border-radius: 0.3rem;
    }

    h1 {
      margin: 0;
      text-align: center;
    }

    .identification {
      dl {
        margin: .5rem;
        padding: 1rem;

        .icon {
          color: $pcb-green; //$fern;
        }

        .green-text {
          color: $pcb-green; //$fern;
        }

        dt {
          margin-bottom: .5rem;
        }
      }

      .broker-character {
        background-image: url('/dist/images/admin/broker-creci-brasil.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 83%;
        height: 16rem;
      }
    }

    .talk-to-broker {
      background-color: $pcb-lighter-green; //$deep-cerulean;
      color: $white;
      margin: 0 1.5rem;
      padding: .5rem 1rem;
      border-radius: 0.5rem;

      .talk-check {
        font-size: 1rem;

        input {
          vertical-align: bottom;
        }
      }

      .btn-primary {
        margin-bottom: 1rem;
        width: 100%;
      }
    }

    .align-center {
      text-align: center;
    }
  }
}

// sass-lint:disable no-important
.auto-contrast {
  .talk-to-broker-right {
    h3,
    span,
    i {
      color: $black !important;
    }

    .talk-to-broker {
      background: $black !important;

      label {
        color: $white !important;
      }

      .btn-primary {
        background: $white !important;
        color: $black !important;
      }
    }
  }
}
