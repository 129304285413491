.animated {
  animation-duration: 1s;
  animation-fill-mode: both;

  &.animated {
    &.infinite {
      animation-iteration-count: infinite;
    }
  }
}

.spin-animation {
  animation: spin 1s linear infinite;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in {
  animation-name: fadeIn;
}

@keyframes fadeInDownBig {
  from {
    opacity: 0;
    transform: translate3d(0, -200px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fade-in-down-big {
  animation-name: fadeInDownBig;
}

@keyframes fadeOutDownBig {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -200px, 0);
  }
}

.fade-out-down-big {
  animation-name: fadeOutDownBig;
}

@keyframes passing-through {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }

  30%,
  70% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-40px);
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }

  30% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
