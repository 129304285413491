.mysite {
  @for $i from 1 through 4 {
    .layout-#{$i} {
      $color-header-bg: nth($grey-1 $blue-1 $pink-1 $green-1, $i);
      $color-header-bg-image: nth(darken($grey-1, 5%) darken($blue-1, 5%) darken($pink-1, 3%) darken($green-1, 10%), $i);
      $color-main-bg: nth(lighten($grey-1, 50%) $blue-lighter-1 lighten($pink-1, 22%) lighten($grey-1, 60%), $i);
      $color-accessory: nth(lighten($grey-1, 12%) darken($blue-1, 5%) darken($pink-1, 5%) darken($green-1, 10%), $i);

      background-color: $color-main-bg;

      .talk-to-broker-right {
        display: none;
      }

      .logo-my-site {
        background-color: $color-font-light-1;
        border: 5px solid $color-font-light-1;
        border-radius: 100%;
        box-sizing: border-box;
        height: 100px;
        min-width: 100px;
        overflow: hidden;
        padding: 12px;
        width: 100px;

        img {
          height: auto;
          width: 100%;
        }

        svg {
          height: auto;
          width: 100%;
        }
      }

      .header-bg {
        background: $color-header-bg;
        background-image: url('/dist/images/fundo-arte-#{$i}.svg');
        background-position: bottom;
        background-repeat: repeat-x;
        padding: 30px 0;

        .container {
          align-content: flex-start;
          align-items: center;
          color: $color-font-light-1;
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: space-between;
          min-height: 150px;

          a {
            color: $color-font-light-1;
            transition: color 1s;

            &:hover {
              color: $color-font-highlight-1;
            }
          }

          @include media-breakpoint-up(md) {
            flex-direction: row;
          }
        }
      }

      .header-bg-line {
        border: 2px solid $color-header-bg-image;
        margin-top: 3px;
      }

      .realtor-information {
        align-content: flex-start;
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;

        .logo-my-site {
          margin-right: 10px;
        }

        .realtor-contact {
          max-width: 500px;
          padding: 10px 20px;

          > * {
            display: block;
            margin-bottom: 5px;
          }

          strong {
            font-size: 16px;
            line-height: 18px;
            text-transform: uppercase;
          }
        }

        @include media-breakpoint-down(md) {
          margin-bottom: 30px;
        }
      }

      .realtor-social {
        padding: 0 20px;
        text-align: center;

        h4 {
          margin-bottom: 10px;
        }

        ul {
          li {
            display: inline;
            margin: 0 5px;
          }
        }

        a {
          display: inline-block;
          text-decoration: none;
        }

        svg {
          fill: currentColor;
          height: 32px;
          width: 32px;
        }
      }

      .realtor-main {
        margin-top: 30px;

        .tabs-creci {
          .head-tabs {
            .nav-item {
              width: 33.33%;

              &:last-of-type {
                margin-right: 0;
              }
            }
          }

          &.primary {
            .head-tabs {
              border-bottom-color: $color-accessory;

              .nav-item {

                &:hover {
                  background: darken($concrete, 10%);
                }

                &.active {
                  background: $color-accessory;
                  border-bottom-color: $color-accessory;

                  a {
                    cursor: default;

                    label {
                      cursor: default;
                    }
                  }
                }
              }
            }
          }
        }

        .form-group {
          select {
            background-image: url('/dist/images/arrow-#{$i}.svg');
            cursor: pointer;
          }
        }

        .gallery {
          .gallery-buttons {

            .btn-primary {
              &:hover {
                background: darken($color-font-highlight-1, 10%);
              }
            }

            .btn-details {
              background: $deep-cerulean;

              &:hover {
                background: darken($color-font-highlight-1, 10%);
              }
            }

            .btn-secondary {
              background: $color-accessory;
              width: 110px;

              &:hover {
                background: darken($color-accessory, 10%);
              }
            }
          }

          .gallery-icons {
            .room-icons {
              color: $color-accessory;

              i {
                color: $color-accessory;
              }
            }

            .btn-favoritar-call {
              .icon-heart-o {
                color: $color-accessory;

                &:hover {
                  color: $color-font-highlight-1;
                }
              }

              .icon-heart {
                color: $color-accessory;

                &:hover {
                  color: lighten($color-accessory, 20%);
                }
              }
            }
          }
        }

        .filters-general {
          color: $color-font-1;
        }

        .filter-wrapper {
          .filter-header {
            background: $color-accessory;
          }
        }

        .filter-wrapper {
          > div {
            margin-bottom: 0;
            padding-bottom: 10px;
          }
        }

        .btn {
          &:hover {
            background: darken($concrete, 10%);
          }
        }
      }

      .lead-page {
        $background-lead-page: rgba(0, 0, 0, .6);
        $background-input: #e0e0e0;
        background-color: $background-lead-page;
        display: none;
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 50000;

        .lead-form {
          background-color: $color-main-bg;
          border: 10px solid $color-font-light-1;
          height: auto;
          left: 50%;
          margin: 0 auto;
          max-width: 760px;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 100%;

          .lead-header {
            align-content: stretch;
            align-items: center;
            background-color: $color-accessory;
            color: $color-font-light-1;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            min-height: 150px;
            padding: 10px;

            .lead-top-message {
              font-size: 16px;
              font-weight: 400;
              line-height: 22px;
              text-align: center;

              span {
                font-size: 18px;
              }

              strong {
                font-weight: 700;
              }

              @include media-breakpoint-down(sm) {
                padding: 0 20px;
                width: 100%;
              }
            }

            .lead-close {
              align-self: flex-start;
              color: $color-font-light-1;
              font-size: 3rem;
              font-weight: 700;
              opacity: .5;
              text-decoration: none;
              text-shadow: 0 1px 0 $color-font-light-1;

              &:hover {
                color: $color-font-highlight-1;
                opacity: 1;
                text-shadow: 0 1px 0 darken($color-font-light-1, 10%);
              }
            }

            .logo-my-site {
              margin-left: 20px;
            }

            @include media-breakpoint-down(xs) {
              .logo-my-site {
                display: none;
              }
            }
          }

          .lead-body {
            color: $color-font-1;
            padding: 15px 20px;

            h1 {
              font-size: 18px;
              line-height: 22px;
              text-align: center;
              text-transform: uppercase;
              width: 100%;
            }

            a {
              color: $color-font-1;
              transition: color 1s;

              &:hover {
                color: $color-font-highlight-1;
              }
            }

            label {
              color: $color-font-1;
            }

            .form-control {
              height: 3rem;
            }

            .lead-button {
              text-align: right;

              .btn {
                &:hover {
                  background: darken($color-font-highlight-1, 10%);
                }
              }
            }


            .form-check-label {
              line-height: 16px;
            }

            @include media-breakpoint-up(sm) {
              .lead-textarea {
                height: 100%;
                max-height: 150px;
              }
            }

            @include media-breakpoint-down(sm) {
              .lead-button {
                margin-top: 10px;
              }
            }
          }

          .lead-input {
            background-color: $background-input;
            border: 0;
            height: 35px;
          }

          .lead-mensagem {
            height: 100px;
            line-height: 17px;
            width: 100%;
          }

          .lead-bottom-message {
            height: 50px;

            p {
              font-style: italic;
              height: 25px;
            }
          }
        }
      }

      .m-loader {
        $background-lead-page: rgba(0, 0, 0, .6);

        align-items: center;
        background-color: $background-lead-page;
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        min-height: 100vh;
        position: fixed;
        top: 0;
        transform: scale(5);
        width: 100%;
        z-index: 70000;
      }

      .m-loader-hidden {
        display: none;
      }

      .c-loader {
        animation: is-rotating 1s infinite;
        border: 2px solid $color-accessory;
        border-radius: 50%;
        border-top-color: $color-accessory;
        height: 15px;
        width: 15px;
      }

      @keyframes is-rotating {
        to {
          transform: rotate(1turn);
        }
      }

      nav {
        .breadcrumb {
          display: flex;
          flex-wrap: wrap;
          list-style: none;
          margin-bottom: 1rem;
          padding: 0 0 1rem;

          .breadcrumb-item {

            &.active {
              color: $color-font-highlight-1;
              font-weight: 700;
            }

            a {
              color: $color-font-highlight-1;
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }
          }

          .breadcrumb-item + .breadcrumb-item {
            &::before {
              content: '>>';
              display: inline-block;
              padding-left: .5rem;
              padding-right: .5rem;
            }
          }
        }
      }

      .my-site-real-estate-details {
        .advertisement {

          &.basic-advertisement {
            background-color: $color-font-light-1;
          }

          .tabs-creci {
            .head-tabs {
              .nav-item {
                background-color: $color-accessory;
                margin-right: 0;
              }
            }

            &.secondary {
              .head-tabs {
                border-bottom-color: $color-accessory;
              }
            }
          }

          .real-state-resume-holder {
            background-color: $color-font-light-1;
          }

          .real-estate-resume {
            margin-bottom: 1rem;
            padding: 2rem;

            h2 {
              color: $color-accessory;
              font-size: 1.8rem;
              font-weight: 700;
              margin-bottom: 1rem;
            }

            .real-estate-value {
              .form-group {
                margin: 0 0 2rem;
              }
            }

            .btn-favoritar-call {
              @include media-breakpoint-down(sm) {
                float: right;
              }

              background-color: $color-accessory;
              color: $color-font-light-1;
              display: none;

              .icon-heart,
              .icon-heart-o {
                display: none;
              }

              .add {
                display: inline-block;
              }

              .remove {
                display: none;
              }

              &.active {
                background-color: $color-accessory;

                .add {
                  display: none;
                }

                .remove {
                  display: inline-block;
                }

                .icon-heart {
                  display: inline-block;
                }

                .icon-heart-o {
                  display: none;
                }
              }
            }

            .icons {
              color: $color-accessory;
              font-size: 1.5rem;
              font-weight: normal;
              margin: 1rem .1rem;

              i {
                margin-right: .75rem;
              }
            }

            .label-value {
              font-size: 1.4rem;
              font-weight: 400;

              &.label-cod {
                font-weight: 700;
              }
            }

            .sale-value {
              color: $color-font-highlight-1;
              font-size: 2.3rem;
              font-weight: 700;
            }

            .square-meter-value {
              color: $color-font-highlight-1;
              font-size: 1.8rem;
              font-weight: 700;
            }

            .realty-social {
              color: $color-accessory;

              h4 {
                margin-bottom: 10px;
              }

              ul {
                li {
                  display: inline;
                  margin: 0 5px;
                }
              }

              a {
                color: $color-accessory;
                display: inline-block;
                text-decoration: none;
                transition: color 1s;

                &:hover {
                  color: $color-font-highlight-1;
                }
              }

              svg {
                fill: currentColor;
                height: 25px;
                width: 25px;
              }
            }
          }

          .grid-icons {
            background-color: $color-font-light-1;
            flex-direction: row;
            margin: 0;
            padding: 2rem 0;
            text-align: center;

            .item-list-icons {
              float: left;
              margin: 0 auto .5rem;
              text-align: center;
              text-transform: uppercase;

              .form-group {
                .num-itens {
                  font-size: 1.5rem;
                  font-weight: bold;
                }

                .desc-itens {
                  font-size: 1rem;
                  font-weight: bold;
                }

                .num-more {
                  font-size: 1.4rem;
                }

                .txt-more {
                  font-size: 1rem;
                  font-weight: 400;
                }
              }

              i {
                color: $color-accessory;
                margin-right: .5rem;
              }

              @include media-breakpoint-up(xs) {
                width: calc(100% / 3);
              }

              @include media-breakpoint-up(md) {
                width: calc(100% / 4);
              }

              @include media-breakpoint-up(lg) {
                width: 12%;
              }
            }

            .item-list-bar {
              background-color: $gray;
              width: 1px;
            }
          }
        }

        .title-description {
          margin: 2rem 0 0;

          > div {
            padding: 0 40px 20px;

            .title {
              margin-bottom: 20px;
            }

            .content-text {
              line-height: 20px;
            }
          }
        }

        .grid-money {
          display: flex;
          justify-content: space-around;
          padding: 2rem;
          text-align: center;
          text-transform: uppercase;
        }

        .mt-15 {
          margin: 1rem 0;
          padding: 2rem;
        }

        .title {
          color: $color-accessory;
          margin: 1rem .1rem;

          &.main-title {
            font-size: 1.8rem;
            text-transform: none;
          }

          &.sub-title {
            font-size: 1.4rem;
          }
        }

        .row-description {
          padding: 2rem;
        }

        .content-text {
          font-size: 1.2rem;
          margin-bottom: 2rem;
          word-wrap: break-word;
        }

        .list-icones {
          dd {
            font-weight: 400;
            margin: 1rem 0;

            i {
              color: $color-accessory;
              margin-right: .4rem;
            }
          }
        }

        dd {
          label {
            font-size: 1.2rem;
          }
        }

        .characteristcs {
          dd {
            @include media-breakpoint-down(sm) {
              width: calc(100% / 3);
            }

            float: left;
            width: calc(100% / 4);
          }
        }

        .map-holder {
          background-color: $color-font-light-1;
          box-shadow: 0 .5rem .5rem $black-shadow-broker;
          margin-top: 1rem;
          padding: .5rem;
        }

        .map-size {
          height: 330px;
        }

        .season-title {
          margin-top: 1.5rem;
          padding: 2rem 2rem 0;
        }

        .season-details {
          padding: 0 3rem;
        }

        .period-table {
          margin-top: 2rem;

          .align-center {
            text-align: center;
            vertical-align: middle;
          }

          tr {
            th {
              background-color: $color-accessory;
              border: 2px solid $color-font-light-1;
              color: $color-font-light-1;
              padding: 1rem;
            }

            .th-first {
              width: 25%;
            }

            td {
              background-color: $color-font-light-1;

              .icon {
                cursor: pointer;
                font-size: 1.5rem;

                :hover {
                  color: $color-accessory;
                }
              }
            }

            .comments {
              background-color: $jagged-ice;
              border: 2px solid $color-font-light-1;
            }
          }
        }

        .margin-entre-hr {
          border: .1rem solid $chicago;
          margin-bottom: 2.6rem;
          margin-top: 2.8rem;
        }

        .images-grid {
          cursor: pointer;

          .broker {
            .broker-name {
              @include media-breakpoint-down(md) {
                font-size: 1rem;
              }

              @include media-breakpoint-down(sm) {
                font-size: 1.7rem;
              }

              font-size: 1.7rem;
            }

            .broker-document {
              @include media-breakpoint-down(md) {
                font-size: .8rem;
              }

              @include media-breakpoint-down(sm) {
                font-size: 1.2rem;
              }

              font-size: 1.2rem;
            }
          }
        }

        .content {
          background: $fern-content-preview;
          color: $grey;
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: center;
          padding: 20px;
          position: absolute;
          text-align: center;
          top: 0;
          width: 100%;
          z-index: 999;

          h1 {
            @include media-breakpoint-down(md) {
              font-size: 3.5rem;
              line-height: 4rem;
            }

            @include media-breakpoint-down(sm) {
              font-size: 2rem;
              line-height: 2.5rem;
            }

            font-size: 5rem;
            font-style: italic;
            line-height: 5.5rem;
            text-transform: uppercase;
            word-wrap: break-word;

            &::before {
              content: open-quote;
            }

            &::after {
              content: close-quote;
            }
          }
        }

        .panel-video {
          video {
            width: 100%;
          }

          .tns-controls {
            top: 60%;
          }
        }

        .advertisement-no-back {

          &.basic-advertisement {
            background-color: $color-font-light-1;
          }

          .sendmessage {
            margin: 1rem 0 0;

            .title {
              color: $color-accessory;
              margin: 1rem .1rem;
              padding-left: 1.8rem;
            }

            .messagecontainer {
              background-color: $color-accessory;
              color: $color-font-light-1;
              font-size: 1.6rem;
              padding: 2rem;

              input,
              textarea {
                border: 0;
                margin: .6rem 0 1rem;
                padding: .5rem;
                width: 100%;
              }

              label {
                > input {
                  margin-right: .5rem;
                  width: auto;
                }
              }

              .label-check {
                font-size: 1.2rem;
                font-weight: 400;

                input {
                  vertical-align: sub;
                }
              }

              .btn {
                float: right;
                font-size: 1.2rem;
                margin: 0;
                margin-top: 1rem;

                &:hover {
                  background: darken($color-font-highlight-1, 10%);
                }
              }
            }
          }
        }

        .grid-calendar {
          background-color: $color-font-light-1;
          padding: 0 0 1.5rem 1.5rem;
        }
      }

      .real-estate {
        .button {
          .btn {
            background: $color-accessory;

            &:hover {
              background: darken($color-accessory, 10%);
            }
          }
        }
      }

      .pagination {
        ol {
          li {
            a {
              &.active {
                background: $color-accessory;

                &:hover {
                  cursor: default;
                }
              }

              &:not(.active) {
                &:hover {
                  background: darken($concrete, 10%);
                  color: $color-font-1;
                }
              }

              &.navigate {
                color: $color-accessory;
              }
            }
          }
        }
        // sass-lint:disable class-name-format
      }


      .noUi-horizontal {
        .noUi-connect {
          background: $color-accessory;
        }
      }

      .noUi-target {
        .noUi-handle {
          background: $color-accessory;
        }
      }
      // sass-lint:enable class-name-format
    }
  }

  .mysite-whatsapp {
    bottom: 12%;
    cursor: pointer;
    display: block;
    float: right;
    position: fixed;
    right: 4px;
    width: 44px;
    z-index: 9999999;
  }

  .mysite-mail {
    bottom: 25%;
    cursor: pointer;
    display: block;
    float: right;
    position: fixed;
    right: 8px;
    width: 40px;
    z-index: 9999999;
  }

  @media only screen and (max-width: 499px) and (min-width: 100px) {
    .mysite-whatsapp {
      bottom: 12%;
      width: 35px;
    }

    .mysite-mail {
      bottom: 25%;
      width: 32px;
    }
  }

  .total-realstate,
  .total-realstateusers {
    display: block;
    font-size: 1.6rem;
    font-weight: 700;
    text-transform: uppercase;

    span {
      display: inline-block;
      text-align: right;
      width: 8rem;
    }
  }

  .total-realstate {
    color: $chicago;
    margin-bottom: 1rem;

    span {
      font-size: 2.6rem;
    }
  }

  .total-realstateusers {
    color: $fern;
    margin-bottom: 2rem;

    span {
      font-size: 2rem;
    }
  }

  .filter-tags {
    .tag {
      background-color: $deep-cerulean;
      color: $color-font-light-1;
      cursor: pointer;
      display: inline-block;
      margin-bottom: .5rem;
      padding: .5rem 2rem .5rem 1rem;
      position: relative;
      text-align: left;


      i {
        position: absolute;
        right: .5rem;
        top: .7rem;
      }
    }
  }

  .mobile-only {
    display: none;
    text-align: right;
  }

  .filter-header {
    display: none;
  }

  @include media-breakpoint-down(sm) {
    .mobile-only {
      display: block;
    }

    .filter-header {
      display: block;
    }

    .filter-wrapper {
      display: none;

      &.active {
        background: $blue-chill;
        bottom: 0;
        display: block;
        height: 100%;
        left: 0;
        overflow: auto;
        padding: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 9;

        .filters-general {
          width: 100%;
        }
      }
    }
  }

  .order-wrapper {
    justify-content: space-between;
  }

  .filter-wrapper {
    @include ad-filter;

    > div {
      margin-bottom: 10px;
    }
  }

  label {
    &.slider-label {
      display: block;
      padding-bottom: 45px;
      width: 100%;
    }
  }
}
