.faleconosco {

  &.index {

    main {

      .main-content {
        background-color: $white;
        margin-bottom: 3rem;
        padding: 2rem 6rem;
      }

      .side-button {
        display: flex;
        justify-content: flex-end;

        input {
          white-space: normal;
        }
      }

      .form-send-question,
      .questions-wrapper {
        display: none;
      }

      .not-visible {
        display: none;
      }
    }
  }
}

// sass-lint:disable no-important
.auto-contrast {
  &.faleconosco {
    form {
      label {
        color: $black !important;
      }
    }
  }
}
