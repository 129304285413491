.awesomplete [hidden] {
  display: none;
}

.awesomplete .visually-hidden {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.awesomplete {
  display: inline-block;
  position: relative;
  z-index: 3;
}

.awesomplete > input {
  display: block;
}

.awesomplete > ul {
  position: absolute;
  left: 0;
  z-index: 1;
  min-width: 100%;
  box-sizing: border-box;
  list-style: none;
  padding: 0;
  margin: 0;
  background: #fff;
}

.awesomplete > ul:empty {
  display: none;
}
