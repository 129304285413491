.outside-properties {

  .card {
    background-color: $white;
    border: 0;
    box-shadow: 0 1px 10px $box-shadown;
    color: $black;
    cursor: pointer;
    display: inline-block;
    margin: 1rem 0;
    outline: none;
    width: 100%;
  }

  .accordion {
    &.active {
      .header {
        border-bottom: .5px solid $nobel;
        margin: 2rem 3rem;
        padding: 0 0 3rem;
      }
    }
  }

  .title-accordion {
    font-size: 1.6rem;
    font-weight: 700;
    margin: 2rem 1rem 2rem 0;
    text-transform: uppercase;
  }

  .icon-accordion {
    background-color: $red-orange;
    border-radius: 50%;
    color: $white;
    display: inline-block;
    font-size: 1.3rem;
    height: 2.1rem;
    padding: .4rem;
    text-align: center;
    width: 2.1rem;
  }

  .pull-left {
    float: left;
  }

  .pull-right {
    float: right;
  }

  .topic {
    font-size: initial;
  }

  .required {
    color: $red;
  }

  .zipcode {
    font-weight: 700;
    margin: 4rem 0 0;
  }

  .video-file-observation {
    float: left;
    font-size: 1.1rem;
    margin: -1.5rem 0 1.5rem;
    text-transform: uppercase;
    width: 100%;
  }

  .photo {
    h3 {
      margin: 2rem 0;
    }
  }

  .add-new {
    background: transparent;
    border: 2px solid $sushi;
    color: $sushi;
    cursor: pointer;
    float: right;
    font-weight: 700;
    height: 3.5rem;
    padding: 0 1rem;
  }

  .admin {
    .realestate {
      .registration {
        .photo {
          .image-wrapper {
            .dropzone {
              .dz-message {
                position: static;
              }
            }
          }
        }
      }
    }
  }

  .dropzone {
    .dz-message {
      bottom: -2rem;
      margin: auto 0;
      position: absolute;
      text-align: center;
      width: 100%;
    }
  }

  .image-wrapper {
    border-bottom: .2rem solid $chicago;
    padding-bottom: 5em;
  }

  .panel {
    margin-bottom: 2em;
  }

  .row-fluid {
    margin-left: 0;
    width: 100%;
  }

  .alert-card-realestate {
    background-color: $alert-gray;
    border: 1px solid transparent;
    border-color: $alert-gray;
    border-radius: .25rem;
    margin-bottom: 1rem;
    padding: 1rem 4rem;
    position: relative;
    //padding: .75rem 1.25rem;
    width: 100%;

    p {
      font-size: 1.7rem;
      padding: 1rem 0;
    }
  }

  .checkbox-cardimmobilefeatures-finality {
    margin-right: 5px;
  }

  .g-recaptcha {
    margin-right: 20px;
  }

  .save-form {
    margin-top: 20px;
  }

  .input-file-flex {
    display: flex;
  }

  .custom-input-file {
    height: .1px;
    overflow: hidden;
    position: absolute;
    width: .1px;
    z-index: -1;
  }

  .chose-file {
    background-color: $deep-cerulean;
    color: $white;
    cursor: pointer;
    height: 4rem;
    line-height: 2rem;
    padding: 1rem;
    text-align: center;
  }

  .file-name {
    border-left: 0;
    display: none;
  }

  .file-uploaded {
    height: 4rem;
  }

  .remove-file-authorization {
    font-size: large;
    line-height: 4rem;
    margin: 0 1rem;
  }

  .is-hidden {
    display: none;
  }
}
