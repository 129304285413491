
.gallery {
  background-color: $white;
  $black-rgba: rgba($black, .2);
  margin-bottom: 3rem;

  .single {
    .tns-controls {
      display: none;
    }
  }

  .gallery-images {
    height: 100%;
    min-height: 250px;

    .images-grid {
      height: 100%;
    }
  }

  .gallery-description {
    @include media-breakpoint-down(md) {
      padding: 2rem 3rem;
    }
    // Necessary for IE 10 and 11
    > .row {
      width: 100%;
    }

    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 2rem 3rem 2rem 0;

    h3 {
      font-weight: normal;
    }
  }

  .gallery-title-details {
    color: $chicago;
    font-size: 2rem;
    line-height: 2.2rem;
    margin: 0 0 .5rem;
  }

  .txt-right {
    text-align: right;
  }

  .span-cod {
    font-size: 1.4rem;
    line-height: 2.2rem;
    margin: 0 0 .5rem;

    .cod-anuncios {
      color: $pcb-green; //$monza;
      font-size: 2rem;
    }
  }

  .gallery-price {
    color: $pcb-green; //$monza;
    font-size: 2rem;
    font-weight: bold;
    line-height: 2.3rem;
    margin: 0 0 .5rem;
  }

  .gallery-address {
    font-size: 1.2rem;
  }

  .gallery-buttons {
    @include media-breakpoint-down(xs) {
      display: block;

      a,
      button {
        margin-bottom: 1rem;
        width: 100%;
      }
    }

    display: flex;
    justify-content: space-between;
    margin: 0 0 1rem;
    width: 100%;

    a,
    button {
      display: block;
      text-align: center;
    }
  }

  .gallery-icons {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: auto 0 1rem;
    width: 100%;

    .btn-favoritar-call {
      text-decoration: none;

      .icon-heart {
        color: $pcb-green; //$deep-cerulean;
        display: none;
      }

      .icon-heart-o {
        color: $pcb-green; //$deep-cerulean;
      }

      &.active {
        .icon-heart {
          display: block;
        }

        .icon-heart-o {
          display: none;
        }
      }
    }

    .room-icons {
      align-items: center;
      color: $pcb-green; //$deep-cerulean;
      display: flex;
    }

    i {
      color: $pcb-green; //$deep-cerulean;
      font-size: 1.6rem;
      margin: 0 1rem 0 .5rem;
    }
  }

  .gallery-spacer {
    flex: 0 0 auto;
  }

  .icon-heart-o {
    float: right;
    margin-right: 1rem;
  }

  .gallery-broker-details {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;

    p {
      margin: 0 0 .5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    i {
      color: $pcb-green; //$fern;
      font-size: 1.2rem;
    }
  }
}
