.pagination {
  padding-bottom: 1rem;

  ol {
    @include media-breakpoint-down(sm) {
      display: block;
    }

    align-items: center;
    display: flex;
    justify-content: center;
    list-style-type: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100%;

    li {
      @include media-breakpoint-down(md) {
        margin-left: .5rem;
        margin-right: .5rem;
      }

      @include media-breakpoint-down(sm) {
        margin-left: .3rem;
        margin-right: .3rem;
      }

      float: left;
      margin-left: 1rem;
      margin-right: 1rem;
      line-height: 2.1rem;

      a {
        color: $black;
        display: block;
        font-weight: 600;
        padding: .6rem 1.1rem;
        text-align: center;
        text-decoration: none;

        &:hover, &.active {
          background: $pcb-darker-green; //$deep-cerulean;
          border-radius: 25%;
          color: $white;
          cursor: pointer;
        }

        &.navigate {
          color: $pcb-darker-green; //$deep-cerulean;
          font-size: 2.1rem;

          &:hover {
            background: none;
            cursor: pointer;
          }
        }
      }
    }
  }
}
