.not-found {
  &.row {
    &.justify-content-center {
      align-items: center;
      background: $white;
      flex-direction: row;
      padding: 2rem;

      .error-wrapper {
        background-color: $white;
        padding: 0;

        img {
          margin-left: 2.1rem;
        }

        .error-title {
          border-bottom: 1px solid $grey;
          color: $deep-cerulean;
          padding: 1.6rem;
        }

        .error-body {
          p {
            margin: 1.6rem;
            text-align: left;
          }
        }

        .back {
          padding: 1.6rem;
          position: relative;
          width: 100%;

          a {
            position: absolute;
            right: 0;
          }

          &.two-btn {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            a {
              position: relative;
            }
          }
        }
      }
    }
  }
}
