.form-group {
  margin: 1rem 0;

  label {
    display: block;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: .5rem;
    text-transform: uppercase;
  }

  [type='text'],
  [type='email'],
  [type='number'],
  [type='tel'],
  [type='date'],
  textarea,
  select,
  .awesomplete {
    width: 100%;
  }

  input[type='checkbox'] {
    cursor: pointer;
  }

  [type='text'],
  [type='email'],
  [type='number'],
  [type='tel'],
  [type='date'],
  textarea,
  select {
    background-color: $white;
    border: 1px solid $alto;
    border-radius: 0;
    padding: 1rem;

    &[readonly],
    &[disabled] {
      background-color: $concrete;
      border: .1rem solid $grey;
      cursor: no-drop;
    }
  }

  [type='text'],
  [type='number'],
  [type='tel'],
  select {
    height: 4rem;
  }

  textarea {
    height: 10rem;
  }
  // sass-lint:disable no-vendor-prefixes
  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url('/dist/images/arrow.svg');
    background-position: center right 1rem;
    background-repeat: no-repeat;
    background-size: 1.5rem;
    padding-right: 3.5rem;

    &::-ms-expand {
      display: none;
    }
  }

  .inline {
    display: flex;

    [type='text'],
    textarea,
    select,
    .awesomplete {
      margin-right: 2rem;
    }
  }

  .input-validation-error {
    border-color: $monza;
  }

  .field-validation-error {
    color: $monza;
    display: block;
    font-weight: bold;
    margin-top: 8px;
  }

  .input-group {
    align-items: center;
    display: flex;

    input {
      border-right: 0;
      margin-right: 0;
    }

    span {
      margin-right: 0;
    }

    .input-group-prepend {
      .input-group-text {
        background-color: $white;
        border: 1px solid $alto;
        border-left: 0;
        font-size: 1.6rem;
        padding: .77rem;
      }
    }
  }

  .file-uploaded {
    background: $white;
    border: 1px solid $alto;
    border-left: 0;
    display: inline-block;
    height: 3.8rem;
    line-height: 1.6rem;
    overflow: hidden;
    padding: 1rem 2rem;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
}
