@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  src: url('/dist/fonts/roboto-regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  src: url('/dist/fonts/roboto-bold.woff') format('woff');
}
