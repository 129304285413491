// Nomenclatura de cores seguindo o http://chir.ag/projects/name-that-color/

// MY SITE COLORS
// MAIN COLORS
$grey-1: #4e4f4f;
$blue-1: #2c698e;
$pink-1: #e497af;
$green-1: #5cbf99;
// ACESSORY COLORS
$blue-lighter-1: #d9e4eb;
$color-font-1: #333;
$color-font-light-1: #fff;
$color-font-highlight-1: #bf002d;
// ADMIN
$cornflower-blue: #6195ed;
$milk-punch-yellow: #fff4d3;
$smith-apple-green: #99e493;
$dove-gray: #6a6a6a;
$turbo-yellow: #f7f200;
$moss-green: #bde2b9;
$pizazz-orange: #ff8c00;
// MAIN COLORS
$monza: #bf002d; // Primary
$deep-cerulean: #007aa5; // Secondary
$fern: #6eae69; // Ternária? + success

//HOME COLORS
$yellow-supernova: #ffcd01;
$gold-buddha: #cda500;
$green-camarone: #006e14;
$blue-ribbon: #015fe8;

// ACESSORY COLORS
$alabaster: #fafafa;
$concrete: #f2f2f2;
$white: #fff;
$white-smoke: #f5f5f5;
$black: #000;
$chicago: #575756;
$silver: #bdbdbd;
$alto: #dcdcdc;
$gallery: #ececec;
$grey: #e0e0e0;
$coral-red: #ff3f3f;
$text-shadow: #969696;
$jagged-ice: #cce4ed;
$black-modal-img: rgba(19, 19, 19, .84);
$fern-content-preview: rgba(110, 174, 105, .8);
$black-rgb: rgba($black, .2);
$black-shadow-broker: rgba(0, 0, 0, .6);
$la-palma: #1a9611;
$your-pink: #fdc8c9;

// Pastel Colors - Admin
$carrot-orange: #f09417;
$sushi: #7cb342;
$red-orange: #fe3637;
$cutty-sark: #546e7a;
$gray: #8b8b8b;
$nobel: #b5b5b5;

$overlay-color: rgba($black, .4);

// SIZES AND MEASURES
$footer-height: 6rem;

// ALERT COLORS
$cherub: #f8d7da;
$zanah: #d4edda;
$cold-turkey: #d4bbbe;
$zanah: #d4edda;
$fringy-flower: #c3e6cb;
$barley-white: #fff3cd;
$colonial-white: #ffeeba;
$alert-gray: #e2e3e5;
$box-shadown: rgba(0, 0, 0, .26);
$cosmos: #ffd3d3;

//ERRO COLORS
$erro-color: #c30000;
$laser-lemon: #ff6;

//GRID
$curious-blue: #1495cd;
$blue-chill: #0b80b2;
$dodger-blue: #3af;
$viking: #5ab5dc;

//INFORMATION BOX
$sweet-corn: #f9dd87;

//TALK TO BROKER
$gallery: #ececec;

// AD DASHBOARD
$eucalyptus: #219653;
$jewel: #158244;
$denim: #0f7cb9;
$jaffa: #f2994a;
$brandy-punch: #db7e2b;
$burnt-sienna: #eb5757;
$valencia: #d24747;
$bright-turquoise: #32eded;
$cream-can: #f2c94c;
$sunglo: #e67070;
$de-york: #6fcf97;
$black-shadow-dashboard: rgba(0, 0, 0, .3);

// auto-contrast
$yellow: #ff0;
$red: #bf002d;

// XML Import Status
$xml-import-em-processamento: hsl(0, 100%, 80%);
$xml-import-processada: #6eae69;
$xml-import-aguardando-processamento: #ff6;

// Defined for usage in app shell
// Must match bootstrap
$app-shell-breakpoints: ( xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px );

// Teste Portal Creci Brasil
$pcb: #ed61dd;
$pcb-green: #00a11d;
$pcb-yellow: #ffcd01;
$pcb-blue: #015fe8;

$pcb-dark-green: darken($pcb-green, 10%);
$pcb-dark-yellow: darken($pcb-yellow, 10%);
$pcb-dark-blue: darken($pcb-blue, 10%);

$pcb-light-green: lighten($pcb-green, 10%);
$pcb-light-yellow: lighten($pcb-yellow, 10%);
$pcb-light-blue: lighten($pcb-blue, 10%);

$pcb-darker-green: #44644a;
$pcb-lighter-green: #99b99f;
